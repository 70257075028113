






















import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator';
import TextItemList, {
  ITextItemList_Item
} from '../modules/TextItem/List';

@Component
export default class TextItemSelect extends Vue {
  @Prop(Boolean) readonly disabled ? : boolean

  isBusy = false;

  searchQuery = '';

  perPage = 10;
  currentPage = 1;

  textItems = new TextItemList();

  fields = [{
    key: 'Text',
    label: this.$t('Text')
  }];

  async fetchTextItems() {
    this.isBusy = true;
    this.textItems = await TextItemList.Fetch(this.currentPage, this.perPage, this.searchQuery);
    this.isBusy = false;
  }

  resetPageAndReloadTextItems() {
    this.pageSelected(1);
  }

  pageSelected(page: number) {
    this.currentPage = page;
    this.fetchTextItems();
  }

  textItemSelected(record: ITextItemList_Item, idx: number) {
    (this as any).$bvModal.hide('select-text-item-modal');
    this.$emit('input', record);
  }
}
